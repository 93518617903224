import {
  Box,
  Container,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ScrollAnimation from "react-animate-on-scroll";
import CarImage from "../assets/car.png";
import { fadeInLeft } from "react-animations";
import styled, { keyframes } from "styled-components";

type AboutProps = {
  isSmallScreen: boolean;
};

const FadeInDiv = styled.div`
  animation: 1s ${keyframes`${fadeInLeft}`};
`;

function About(props: AboutProps) {
  const { isSmallScreen } = props;
  const isLgScreen = useMediaQuery((responsiveTheme: Theme) =>
    responsiveTheme.breakpoints.down("lg")
  );
  return (
    <Box>
      {isLgScreen ? (
        <FadeInDiv>
          <Container sx={{ width: "100%" }}>
            <Typography
              variant="h1"
              color={(theme) => theme.custom.gold_light}
              sx={{ textAlign: "center" }}
            >
              Om
            </Typography>
            <Grid container>
              <Grid
                item
                xs={isSmallScreen ? 12 : 7}
                sx={{ alignContent: "center" }}
              >
                <Typography variant="h5">
                  Vi åker hem till dig och rekondar din bil invändigt!
                  Alternativt åker till närmaste tvätthall och utför utvändig
                  tvätt. Sitt kvar hemma i soffan, lägg en bokning och få din
                  bil tvättad av oss.
                </Typography>
              </Grid>
              <Grid
                item
                xs={isSmallScreen ? 12 : 5}
                sx={{ textAlign: "center", alignContent: "center" }}
              >
                <img
                  src={CarImage}
                  alt="Car image"
                  style={{ maxHeight: "300px", maxWidth: "100%" }}
                />
              </Grid>
            </Grid>
          </Container>
        </FadeInDiv>
      ) : (
        <ScrollAnimation animateIn="fadeInLeft" animateOnce="true">
          <Container sx={{ width: "100%" }}>
            <Typography
              variant="h1"
              color={(theme) => theme.custom.gold_light}
              sx={{ textAlign: "center" }}
            >
              Om
            </Typography>
            <Grid container>
              <Grid item xs={12} sm={7} sx={{ alignContent: "center" }}>
                <Typography variant="h5">
                  Vi åker hem till dig och rekondar din bil invändigt!
                  Alternativt åker till närmaste tvätthall och utför utvändig
                  tvätt. Sitt kvar hemma i soffan, lägg en bokning och få din
                  bil tvättad av oss.
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={5}
                sx={{ textAlign: "center", alignContent: "center" }}
              >
                <img
                  src={CarImage}
                  alt="Car image"
                  style={{ maxHeight: "300px", maxWidth: "100%" }}
                />
              </Grid>
            </Grid>
          </Container>
        </ScrollAnimation>
      )}
    </Box>
  );
}

export default About;
