import { Box, Container, Grid, Typography } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import EmailIcon from "@mui/icons-material/Email";
import ScrollAnimation from "react-animate-on-scroll";

const TikTokIcon = ({ color = "#FFFFFF" }) => {
  return (
    <svg
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width="32px"
      height="32px"
      style={{ marginRight: "10px" }}
    >
      <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
    </svg>
  );
};

type ContactProps = {
  isSmallScreen: boolean;
};

function Contact(props: ContactProps) {
  const { isSmallScreen } = props;
  return (
    <Box>
      <ScrollAnimation animateIn="fadeInRight" animateOnce="true">
        <Typography
          variant="h1"
          color={(theme) => theme.custom.gold_light}
          sx={{ textAlign: "center" }}
        >
          Kontakt
        </Typography>
        <Container
          sx={{ height: "100%", display: "flex", alignItems: "center" }}
        >
          <Grid container>
            <Grid item xs={12} md={6} sx={{ alignContent: "center" }}>
              <Typography variant="h5">
                Boka en biltvätt genom att skicka ett DM på vår Instagram eller
                skicka ett mail till oss. Vi tvättar bilar i Vara, Skara och Lidköping med
                omnejd.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} display={"flex"} justifyContent={"end"}>
              <Grid
                container
                display={"flex"}
                justifyContent={"center"}
                sx={{ paddingTop: isSmallScreen ? "50px" : "0" }}
              >
                <Grid item xs={12} lg={8} display={"flex"}>
                  <InstagramIcon
                    sx={{ height: "32px", width: "32px", marginRight: "10px" }}
                  ></InstagramIcon>
                  <Typography variant={isSmallScreen ? "h6" : "h5"}>
                    <a
                      href="https://www.instagram.com/ffrekond/"
                      target="_blank"
                      style={{ color: "inherit" }}
                    >
                      Instagram
                    </a>
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={8} display={"flex"}>
                  <TikTokIcon />
                  <Typography variant={isSmallScreen ? "h6" : "h5"}>
                    <a
                      href="https://www.tiktok.com/@forsbergsrekond"
                      target="_blank"
                      style={{ color: "inherit" }}
                    >
                      Tiktok
                    </a>
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={8} display={"flex"}>
                  <EmailIcon
                    sx={{ height: "32px", width: "32px", marginRight: "10px" }}
                  ></EmailIcon>
                  <Typography variant={isSmallScreen ? "body2" : "h5"}>
                    <a
                      href="mailto:forsbergsrekond@hotmail.com"
                      style={{ color: "inherit" }}
                    >
                      forsbergsrekond@hotmail.com
                    </a>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInLeft" animateOnce="true">
        <Container sx={{ marginTop: "30px" }}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d16789.99448001235!2d12.95364!3d58.26264200000001!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465acfa73024beff%3A0x48450a81c97bafe9!2sForsbergs%20Rekond!5e0!3m2!1ssv!2sse!4v1715287634675!5m2!1ssv!2sse"
            width="100%"
            height="400"
            loading="lazy"
          ></iframe>
        </Container>
      </ScrollAnimation>
    </Box>
  );
}

export default Contact;
