import "./App.css";
import "animate.css/animate.compat.css";
import {
  Container,
  CssBaseline,
  Paper,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import Home from "./pages/Home";
import React from "react";
import { MenuItem } from "./components/MenuItem";
import DesktopNavBar from "./components/DesktopNavBar";
import MobileNavBar from "./components/MobileNavbar";
import { Route, Routes } from "react-router-dom";
import Footer from "./components/Footer";

declare module "@mui/material/styles" {
  interface Theme {
    custom: {
      gold_light: string;
      grey_dark: string;
      grey_light: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    custom?: {
      gold_light?: string;
      grey_dark?: string;
      grey_light?: string;
    };
  }
}

function App() {
  const [menuValue, setMenuValue] = useState("Home");
  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  const theme = createTheme({
    palette: {
      mode: "dark",
    },
    custom: {
      gold_light: "#AD974F",
      grey_dark: "#231F20",
      grey_light: "#EAEAEA",
    },
    typography: {
      fontFamily: '"Asap"',
      h1: {
        padding: "1rem 0",
        fontSize: "2.5rem",
        fontWeight: "700",
        color: "#FFFFFF",
      },
      h2: {
        padding: "1rem 0",
        fontSize: "2rem",
        color: "#FFFFFF",
      },
      h3: {
        padding: "1 rem 0",
        fontSize: "1.5rem",
        color: "#FFFFFF",
      },
      body1: {
        padding: "0.75rem 0",
        fontSize: "1.25rem",
        color: "#FFFFFF",
      },
      body2: {
        padding: "0.25rem 0",
        fontSize: "1.10rem",
        color: "#FFFFFF",
      },
    },
  });

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setMenuValue(newValue);
  };

  const handleChangeValue = (newValue: string) => {
    setMenuValue(newValue);
  };

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  function getIfPortraitMode() {
    return screenSize.height > screenSize.width;
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };

    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  });

  const menuItems: Array<MenuItem> = [
    {
      Text: "HEM",
      Path: "home",
    },
    {
      Text: "OM",
      Path: "about",
    },
    {
      Text: "PAKET",
      Path: "services",
    },
    {
      Text: "KONTAKT",
      Path: "contact",
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Paper
        sx={{
          height: "100vh",
          width: "100%",
        }}
        elevation={0}
      >
        <Container
          sx={{
            "&.MuiContainer-root": {
              maxWidth: "100%",
              paddingLeft: "0",
              paddingRight: "0",
            },
          }}
        >
          {getIfPortraitMode() ? (
            <MobileNavBar
              handleChange={handleChangeValue}
              menuItems={menuItems}
            ></MobileNavBar>
          ) : (
            <DesktopNavBar
              menuValue={menuValue}
              handleChange={handleChange}
              menuItems={menuItems}
            ></DesktopNavBar>
          )}

          <Routes>
            <Route
              path="/"
              element={<Home handleChangeValue={handleChangeValue}></Home>}
            />
          </Routes>

          <Footer isSmallScreen={getIfPortraitMode()}></Footer>
        </Container>
      </Paper>
    </ThemeProvider>
  );
}

export default App;
