import { ServiceItem } from "../Types/ServiceItem";

export const ServicesList = (): ServiceItem[] => [
  {
    id: 0,
    icon: "",
    serviceName: "Underhåll",
    serviceDescription: "En snabb och lätt utvändig tvätt. Underhållstvätt.",
    servicePrice: 295,
    titleColor: "#8c5f31",
  },
  {
    id: 1,
    icon: "",
    serviceName: "Brons",
    serviceDescription:
      "Snabb tvätt. Endast utvändig för 495kr eller endast invändig för 695kr.",
    servicePrice: 495,
    titleColor: "#CD7F32",
  },
  {
    id: 2,
    icon: "",
    serviceName: "Silver",
    serviceDescription:
      "Väldigt grundlig utvändig och invändig tvätt. Keramisk quick coat ingår.",
    servicePrice: 995,
    titleColor: "#a1a1a1",
  },
  {
    id: 3,
    icon: "",
    serviceName: "Guld",
    serviceDescription:
      "Väldigt grundlig utvändig och invändig tvätt. Lackskydd samt fläckborttagning ingår.",
    servicePrice: 1495,
    titleColor: "#d4af37",
  },
];
