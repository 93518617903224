import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Grid } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import EmailIcon from "@mui/icons-material/Email";

const TikTokIcon = ({ color = "#FFFFFF" }) => {
  return (
    <svg
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width="25px"
      height="25px"
      style={{ marginRight: "10px" }}
    >
      <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
    </svg>
  );
};

type FooterProps = {
  isSmallScreen: boolean;
};

export default function StickyFooter(props: FooterProps) {
  const { isSmallScreen } = props;
  return (
    <Box
      component="footer"
      sx={{
        mt: "auto",
        width: "100%",
        backgroundColor: "#806f3b",
      }}
    >
      <Container sx={{ minHeight: "200px", alignContent: "center" }}>
        <Grid container sx={{ height: "100%", alignItems: "center" }}>
          <Grid item xs={9} sm={6}>
            <Typography variant="body2">
              {"Copyright © "}
              Forsbergs Rekond {new Date().getFullYear()}
              {". All rights reserved"}
            </Typography>
            <Typography variant="body2">Stora Torget, 534 31 Vara</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container justifyContent={"end"}>
              <Grid item xs={12} sm={7} display={"flex"} alignItems={"center"}>
                <InstagramIcon
                  sx={{ height: "25px", width: "25px", marginRight: "10px" }}
                ></InstagramIcon>
                <Typography variant={"body2"}>
                  <a
                    href="https://www.instagram.com/ffrekond/"
                    target="_blank"
                    style={{ color: "inherit" }}
                  >
                    Instagram
                  </a>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={7} display={"flex"} alignItems={"center"}>
                <TikTokIcon />
                <Typography variant={"body2"}>
                  <a
                    href="https://www.tiktok.com/@forsbergsrekond"
                    target="_blank"
                    style={{ color: "inherit" }}
                  >
                    Tiktok
                  </a>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={7} display={"flex"} alignItems={"center"}>
                <EmailIcon
                  sx={{ height: "25px", width: "25px", marginRight: "10px" }}
                ></EmailIcon>
                <Typography variant={"body2"}>
                  <a
                    href="mailto:forsbergsrekond@hotmail.com"
                    style={{ color: "inherit" }}
                  >
                    forsbergsrekond@hotmail.com
                  </a>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
