import { Box, Typography, useMediaQuery } from "@mui/material";
import {
  Theme,
  createTheme,
  responsiveFontSizes,
  useTheme,
} from "@mui/material/styles";
import carWashVideoMP4 from "../assets/CarWashVideo.mp4";
import videoMobile from "../assets/CarVacuumVideo.mp4";
import About from "./About";
import Contact from "./Contact";
import Services from "./Services";
import { fadeIn } from "react-animations";
import styled, { keyframes } from "styled-components";

let responsiveTheme = createTheme();
responsiveTheme = responsiveFontSizes(responsiveTheme);

const FadeInDiv = styled.div`
  animation: 3s ${keyframes`${fadeIn}`};
`;

type HomeProps = {
  handleChangeValue: (newValue: string) => void;
};

function Home(props: HomeProps) {
  const theme = useTheme();
  const isSmScreen = useMediaQuery((responsiveTheme: Theme) =>
    responsiveTheme.breakpoints.down("sm")
  );
  return (
    <>
      <Box
        id="home"
        sx={{
          maxHeight: "70vh",
          position: "relative",
          maxWidth: "100%",
          overflow: "hidden",
          backgroundColor: theme.custom.grey_dark,
        }}
      >
        <FadeInDiv>
          <video
            width="100%"
            autoPlay
            muted
            loop
            disablePictureInPicture
            playsInline
            style={{ opacity: isSmScreen ? 0.8 : 0.5 }}
          >
            <source
              src={isSmScreen ? videoMobile : carWashVideoMP4}
              type="video/mp4"
            ></source>
          </video>
          <Typography
            variant={isSmScreen ? "h3" : "h1"}
            sx={{
              width: "100%",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              color: "white",
              alignContent: "center",
              textAlign: "center",
              fontSize: isSmScreen ? "2rem" : "4rem",
              fontWeight: "bold",
              backgroundColor: "rgba(0,0,0, 0.5)",
            }}
          >
            Åker hem till dig och rekondar din bil
          </Typography>
        </FadeInDiv>
      </Box>
      <div id="about" style={{ marginTop: "90px", marginBottom: "90px" }}>
        <About isSmallScreen={isSmScreen}></About>
      </div>
      <div id="services" style={{ marginBottom: "80px" }}>
        <Services isSmallScreen={isSmScreen}></Services>
      </div>
      <div id="contact" style={{ paddingBottom: "90px" }}>
        <Contact isSmallScreen={isSmScreen}></Contact>
      </div>
    </>
  );
}

export default Home;
