import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ServiceCard from "../components/ServiceCard";
import { ServicesList } from "../Data/ServicesList";
import ScrollAnimation from "react-animate-on-scroll";
import { TikTokEmbed } from "react-social-media-embed";

type ServicesProps = {
  isSmallScreen: boolean;
};

const Services = (props: ServicesProps) => {
  const servicesList = ServicesList();
  const { isSmallScreen } = props;
  const isMScreen = useMediaQuery((responsiveTheme: Theme) =>
    responsiveTheme.breakpoints.down("md")
  );

  const services = servicesList.map((serviceItem) => (
    <Grid
      item
      xs={12}
      sm={6}
      md={6}
      lg={3}
      key={serviceItem.id}
      sx={{ display: "flex", justifyContent: "center" }}
    >
      <ServiceCard
        service={serviceItem}
        isSmallScreen={isSmallScreen}
      ></ServiceCard>
    </Grid>
  ));

  return (
    <Container sx={{ textAlign: "center" }}>
      <ScrollAnimation animateIn="fadeInRight" animateOnce="true">
        <Typography
          variant="h1"
          color={(theme) => theme.custom.gold_light}
          sx={{ paddingBottom: "30px" }}
        >
          Paket
        </Typography>
        <Container
          sx={{ display: "flex", alignItems: "center", paddingBottom: "30px" }}
        >
          <Grid container spacing={5}>
            {services}
          </Grid>
        </Container>
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInLeft" animateOnce="true">
        <Grid container spacing={5} sx={{ justifyContent: "center" }}>
          <Grid item sx={{ justifyContent: "center" }}>
            <Typography
              variant="h2"
              sx={{ fontSize: "34px", fontWeight: "700" }}
              color={(theme) => theme.custom.gold_light}
            >
              Tillägg
            </Typography>
            <Container>
              <Typography variant="h2" sx={{ fontSize: "24px" }}>
                Milersättning 30:-/Mil
              </Typography>
              <Typography variant="h2" sx={{ fontSize: "24px" }}>
                Eventuell hallhyra X:-
              </Typography>
            </Container>
          </Grid>
          <Grid item sx={{ justifyContent: "center" }}>
            <Typography
              variant="h1"
              sx={{ fontSize: "34px", fontWeight: "700" }}
              color={(theme) => theme.custom.gold_light}
            >
              Extra tillval
            </Typography>
            <Container>
              <Typography variant="h2" sx={{ fontSize: "24px" }}>
                Lackskydd 495:-
              </Typography>
              <Typography variant="h2" sx={{ fontSize: "24px" }}>
                Fläckborttagning 395:-
              </Typography>
              <Typography variant="h2" sx={{ fontSize: "24px" }}>
                Päls/Hår borttagning 395:-
              </Typography>
              <Typography variant="h2" sx={{ fontSize: "24px" }}>
                Bagageutrymme, pris enligt ö.k
              </Typography>
            </Container>
          </Grid>
        </Grid>
      </ScrollAnimation>

      {isMScreen ? (
        <></>
      ) : (
        <Grid
          container
          spacing={5}
          style={{ justifyContent: "center", marginTop: "30px" }}
        >
          <Grid item>
            <TikTokEmbed
              url="https://www.tiktok.com/@forsbergsrekond/video/7350516702385376545?is_from_webapp=1&sender_device=pc&web_id=7367013061221860896"
              width={325}
              height={580}
            />
          </Grid>
          <Grid item>
            <TikTokEmbed
              url="https://www.tiktok.com/@forsbergsrekond/video/7355925879974939936?is_from_webapp=1&sender_device=pc&web_id=7367013061221860896"
              width={325}
              height={580}
            />
          </Grid>
          <Grid item>
            <TikTokEmbed
              url="https://www.tiktok.com/@forsbergsrekond/video/7350306098819321121?is_from_webapp=1&sender_device=pc&web_id=7367013061221860896"
              width={325}
              height={580}
            />
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default Services;
