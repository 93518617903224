import { Box, Button, Grid, Typography } from "@mui/material";
import { MenuItem } from "./MenuItem";
import { Link } from "react-scroll";
import { useNavigate } from "react-router-dom";

type DesktopNavBarProps = {
  menuValue: string;
  handleChange: Function;
  menuItems: Array<MenuItem>;
};

const DesktopNavBar = (props: DesktopNavBarProps) => {
  const { menuItems } = props;
  const navigate = useNavigate();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <Grid
      container
      alignItems={"center"}
      sx={{
        position: "sticky",
        zIndex: "1000",
        top: "0",
        height: "100px",
        backgroundColor: (theme) => theme.palette.background.default,
        boxShadow: "0 4px 2px -2px rgba(0,0,0,.2)",
        padding: "0 16px",
      }}
    >
      <Grid item xs={3}>
        <Link
          to={"home"}
          spy={true}
          smooth={true}
          offset={-64}
          duration={500}
          key={"homeButton"}
        >
          <Button
            className="TextButton"
            onClick={() => {
              navigate("/");
              scrollToTop();
            }}
            sx={{ textTransform: "initial", padding: "0" }}
          >
            <Typography
              variant="h2"
              sx={{
                display: "flex",
                color: (theme) => theme.custom.gold_light,
              }}
            >
              FORSBERGS REKOND
            </Typography>
          </Button>
        </Link>
      </Grid>
      <Grid item xs={9}>
        <Box
          sx={{
            height: "50px",
            display: "flex",
            justifyContent: "end",
          }}
        >
          {menuItems.map((item) => (
            <Link
              to={item.Path.split(" ").join("")}
              spy={true}
              smooth={true}
              offset={-80}
              duration={500}
              key={item.Text}
            >
              <Button
                className="TextButton"
                onClick={() =>
                  navigate("/", { state: { targetId: item.Path } })
                }
                sx={{
                  minHeight: "48px",
                  pt: 0,
                  pb: 0,
                  "&.Mui-selected": {
                    fontWeight: "700",
                  },
                  overflow: "visible",
                  textTransform: "initial",
                  padding: "10px",
                  marginLeft: "20px",
                }}
              >
                <Typography variant="h3">{item.Text}</Typography>
              </Button>
            </Link>
          ))}
        </Box>
      </Grid>
    </Grid>
  );
};
export default DesktopNavBar;
