import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { ServiceItem } from "../Types/ServiceItem";
import { useNavigate } from "react-router-dom";

type ServiceCardProps = {
  service: ServiceItem;
  isSmallScreen: boolean;
};

const ServiceCard = (props: ServiceCardProps) => {
  const { service, isSmallScreen } = props;
  return (
    <Card
      className="ServiceCard"
      sx={{
        width: isSmallScreen ? "250px" : "300px",
        minHeight: "275px",
        textAlign: "center",
        boxShadow: "5px 5px 10px 5px rgba(0,0,0,0.5)",
        cursor: "pointer",
      }}
    >
      <Box
        sx={{
          height: "100%",
          position: "relative",
          padding: "30px 16px"
        }}
      >
        <Typography variant="h2" fontWeight={900} sx={{ color: service.titleColor }}>
          {service.serviceName}
        </Typography>
        <Typography variant="h3" fontWeight={700}>
          Från {service.servicePrice} SEK
        </Typography>
        <Typography variant="body1">{service.serviceDescription}</Typography>
      </Box>
    </Card>
  );
};

export default ServiceCard;
