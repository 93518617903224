import {
  Box,
  Button,
  Drawer,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { MenuItem } from "./MenuItem";
import { useState } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-scroll";
import { useNavigate } from "react-router-dom";

type MobileNavBarProps = {
  handleChange: Function;
  menuItems: Array<MenuItem>;
};

const MobileNavBar = (props: MobileNavBarProps) => {
  const { menuItems } = props;

  const [open, setState] = useState(false);
  const navigate = useNavigate();

  const toggleDrawer =
    (open: boolean) => (event: React.SyntheticEvent<Element, Event>) => {
      setState(open);
    };

  return (
    <Grid
      container
      alignItems={"center"}
      sx={{
        position: "sticky",
        zIndex: "1000",
        top: "0",
        backgroundColor: (theme) => theme.palette.background.default,
        padding: "0 16px",
      }}
    >
      <Grid item xs={11}>
        <Link
          to={"home"}
          spy={true}
          smooth={true}
          offset={-64}
          duration={500}
          key={"homeButton"}
        >
          <Button
            onClick={() => navigate("/")}
            sx={{ textTransform: "initial", padding: "0" }}
          >
            <Typography
              variant="body1"
              sx={{
                display: "flex",
                fontWeight: "700",
                color: (theme) => theme.custom.gold_light,
              }}
            >
              FORSBERGS REKOND
            </Typography>
          </Button>
        </Link>
      </Grid>
      <Grid
        item
        xs={1}
        sx={{
          alignContent: "end",
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer(true)}
          sx={{
            mr: 2,
            display: {
              xs: "flex",
            },
            width: "100%"
          }}
        >
          <MenuIcon />
        </IconButton>

        <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
          <Box
            sx={{
              p: 2,
              height: 1,
              backgroundColor: (theme) => theme.palette.background.paper,
            }}
          >
            <IconButton sx={{ mb: 2 }} onClick={toggleDrawer(false)}>
              <CloseIcon />
            </IconButton>

            <Box sx={{ mb: 2 }}>
              {menuItems.map((item) => (
                <Link
                  to={item.Path.split(" ").join("")}
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  key={item.Text}
                >
                  <Box
                    sx={{
                      borderBottom: "1px solid",
                      borderColor: "rgba(255, 255, 255, 0.12)",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <ListItemButton
                      key={item.Text}
                      onClick={() =>
                        navigate("/", { state: { targetId: item.Path } })
                      }
                      style={{ alignItems: "center", display: "flex" }}
                    >
                      <ListItemText
                        sx={{
                          minHeight: "30px",
                          pt: 0,
                          pb: 0,
                          alignItems: "center",
                          display: "flex",
                        }}
                        primary={
                          <Typography sx={{ fontSize: 20 }}>
                            {item.Text}
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </Box>
                </Link>
              ))}
            </Box>
          </Box>
        </Drawer>
      </Grid>
    </Grid>
  );
};

export default MobileNavBar;
